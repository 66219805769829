import store from "../redux/store";
import Web3 from "web3";
import {makeBatchRequest} from "./promisify";
import {updateBalances, updateInfo, updateLPInfo, updateStaked, updateTotals} from "../redux/slices/coineusSlice";
import coineus_abi from "../abis/ceus_abi.json";
import ceusone_abi from "../abis/Coineus/CEUSONE.json";
import consensus_abi from "../abis/consensus_abi.json";
import uni_pair_abi from "../abis/IUniswapV2Pair.json";
import tokenList from "../utils/tokenList.json";

export default function getUserBalances(wallet_address) {
    const _store = store.getState();
    const {pairs, rpc} = _store.coineus;

    if (wallet_address === undefined) {
        return;
    }

    getFUSE(pairs, rpc.fuse, wallet_address);
    getAVAX(pairs, rpc.avax, wallet_address);
    getBNB(pairs, rpc.bnb, wallet_address);
    getETH(pairs, rpc.eth, wallet_address);
}

function getFUSE(pairs, rpc, wallet_address) {

    const web3 = new Web3(rpc);
    const fuseone_contract = new web3.eth.Contract(ceusone_abi, "0xadF951967082452c46eA853158e0838F0EB6fee5");
    const ceus_contract = new web3.eth.Contract(coineus_abi, "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8");
    const ceuse_contract = new web3.eth.Contract(coineus_abi, "0x31Db8DC955a281d2fefAB5709d1Dfd9603679222");
    const ceusone_contract = new web3.eth.Contract(ceusone_abi, "0xE27431FACb1F093BA164c9F86B3f484b39ad54CC");
    const fusd_contract = new web3.eth.Contract(coineus_abi, '0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F');
    const volt_contract = new web3.eth.Contract(coineus_abi, '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4');
    const lit_contract = new web3.eth.Contract(coineus_abi, '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F');
    const gooddollar_contract = new web3.eth.Contract(coineus_abi, '0x495d133B938596C9984d462F007B676bDc57eCEC');
    const fvksrg_contract = new web3.eth.Contract(coineus_abi, '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61');
    const lady_contract = new web3.eth.Contract(coineus_abi, '0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A');
    const foo_contract = new web3.eth.Contract(coineus_abi, '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9');
    const dgnx_contract = new web3.eth.Contract(coineus_abi, '0x13125265584D58cd45075e1efF47aEe083f4C1C6');

    const btc_contract = new web3.eth.Contract(coineus_abi, '0x33284f95ccb7B948d9D352e1439561CF83d8d00d');
    const eth_contract = new web3.eth.Contract(coineus_abi, '0xa722c13135930332Eb3d749B2F0906559D2C5b99');
    const bnb_contract = new web3.eth.Contract(coineus_abi, '0x6acb34b1Df86E254b544189Ec32Cf737e2482058');
    const wfuse_contract = new web3.eth.Contract(coineus_abi, '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629');

    const coineusValidatorAddress = "0xb27feaab8e4f4423e0b9c6f743e26e23a5c0c1b3";
    const consensusAddress = "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79";
    const consensusContract = new web3.eth.Contract(consensus_abi, consensusAddress);

    const ceus_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x87440F829E7ED808599749630555080917c4b3D9") //CEUS/LIT LP
    const ceus_fuse_contract = new web3.eth.Contract(uni_pair_abi, "0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b") //CEUS/FUSE LP
    const fuse_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc") //LIT/FUSE LP
    const ceus_lady_contract = new web3.eth.Contract(uni_pair_abi, "0xcEAB8d0303924D3DB5B3a3eD178F055603398845") //$LADY/CEUS LP
    const fvksrg_fuse_contract = new web3.eth.Contract(uni_pair_abi, "0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD") //FVKSRG/FUSE LP
    const fvksrg_ceus_contract = new web3.eth.Contract(uni_pair_abi, "0xB6850233c408Bbf58c3B2beC945394114cB08Fd0") //FVKSRG/CEUS LP
    const dgnx_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61") //WFUSE/DGNX LP
    const lady_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586") //WFUSE/LADY LP
    const foo_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x4f47a2c360266230795CFBca80b7C89EdA52Cd52") //WFUSE/FOO LP



    const ceus_lit_farm =  new web3.eth.Contract(coineus_abi, "0xC2B83a3C3199Fb29Ce1d8Dfc681001AF3e97e004") //CEUS/LIT LP
    const ceus_lady_farm =  new web3.eth.Contract(coineus_abi, "0xbbd77F9475de999628A6C0f5b31589585fBbc119"); //CEUS/$LADY
    const ceus_fuse_farm =  new web3.eth.Contract(coineus_abi, "0x7243265f91e138a11548DF4253E3ec056b6348f8") //CEUS/FUSE LP
    const ceus_fvksrg_farm =  new web3.eth.Contract(coineus_abi, "0x04423CAE1BD000659d02ee4EdE30446C3e25AbBF") //CEUS/FVKSRG LP
    const fuse_lit_farm =  new web3.eth.Contract(coineus_abi, "0x8614bf32bC66ced3F5FD559379A17874347acBae") //LIT/FUSE LP
    const fuse_fvksrg_farm =  new web3.eth.Contract(coineus_abi, "0xE052F79F766d0080f44Ac28C29CcB4F7845D15D7") //FVKSRG/FUSE LP
    const lady_wfuse_farm =  new web3.eth.Contract(coineus_abi, "0xEab321D2eF270fA78bADCd1d0D037c9203b4B68b") //CEUS/FVKSRG LP
    const foo_wfuse_farm =  new web3.eth.Contract(coineus_abi, "0x92958736B792F97B9296678A5C9509a2a5b8af2F") //CEUS/FVKSRG LP

    //staking - earn
    const foo_earn_contract = new web3.eth.Contract(coineus_abi, "0x225b81A6aCaF6448d74e30190375b12970D5Fa30");
    const fvksrg_earn_contract = new web3.eth.Contract(coineus_abi, "0xAEC89F494E9afd1978590d2cD5Dcc4067bB8789D");

    const calls = [
        web3.eth.getBalance(wallet_address),
        fuseone_contract.methods.balanceOf(wallet_address).call,
        fuseone_contract.methods.getAccount(wallet_address).call,
        ceus_contract.methods.balanceOf(wallet_address).call,
        ceuse_contract.methods.balanceOf(wallet_address).call,
        ceusone_contract.methods.balanceOf(wallet_address).call,
        ceusone_contract.methods.getAccount(wallet_address).call,
        fusd_contract.methods.balanceOf(wallet_address).call,
        volt_contract.methods.balanceOf(wallet_address).call,
        consensusContract.methods.delegatedAmount(wallet_address, coineusValidatorAddress).call,
        lit_contract.methods.balanceOf(wallet_address).call,
        gooddollar_contract.methods.balanceOf(wallet_address).call,
        fvksrg_contract.methods.balanceOf(wallet_address).call,
        lady_contract.methods.balanceOf(wallet_address).call,
        foo_contract.methods.balanceOf(wallet_address).call,
        dgnx_contract.methods.balanceOf(wallet_address).call,

        btc_contract.methods.balanceOf(wallet_address).call,
        eth_contract.methods.balanceOf(wallet_address).call,
        bnb_contract.methods.balanceOf(wallet_address).call,
        wfuse_contract.methods.balanceOf(wallet_address).call,

        ceus_lit_contract.methods.balanceOf(wallet_address).call,
        ceus_lit_contract.methods.getReserves().call,
        ceus_lit_contract.methods.totalSupply().call,

        ceus_fuse_contract.methods.balanceOf(wallet_address).call,
        ceus_fuse_contract.methods.getReserves().call,
        ceus_fuse_contract.methods.totalSupply().call,

        fuse_lit_contract.methods.balanceOf(wallet_address).call,
        fuse_lit_contract.methods.getReserves().call,
        fuse_lit_contract.methods.totalSupply().call,

        ceus_lit_farm.methods.balanceOf(wallet_address).call,
        ceus_fuse_farm.methods.balanceOf(wallet_address).call,
        ceus_lady_farm.methods.balanceOf(wallet_address).call,
        fuse_lit_farm.methods.balanceOf(wallet_address).call,
        fuse_fvksrg_farm.methods.balanceOf(wallet_address).call,
        ceus_fvksrg_farm.methods.balanceOf(wallet_address).call,
        lady_wfuse_farm.methods.balanceOf(wallet_address).call,
        foo_wfuse_farm.methods.balanceOf(wallet_address).call,

        ceus_lady_contract.methods.balanceOf(wallet_address).call,
        ceus_lady_contract.methods.getReserves().call,
        ceus_lady_contract.methods.totalSupply().call,

        fvksrg_fuse_contract.methods.balanceOf(wallet_address).call,
        fvksrg_fuse_contract.methods.getReserves().call,
        fvksrg_fuse_contract.methods.totalSupply().call,

        fvksrg_ceus_contract.methods.balanceOf(wallet_address).call,
        fvksrg_ceus_contract.methods.getReserves().call,
        fvksrg_ceus_contract.methods.totalSupply().call,

        dgnx_wfuse_contract.methods.balanceOf(wallet_address).call,
        dgnx_wfuse_contract.methods.getReserves().call,
        dgnx_wfuse_contract.methods.totalSupply().call,

        lady_wfuse_contract.methods.balanceOf(wallet_address).call,
        lady_wfuse_contract.methods.getReserves().call,
        lady_wfuse_contract.methods.totalSupply().call,

        foo_wfuse_contract.methods.balanceOf(wallet_address).call,
        foo_wfuse_contract.methods.getReserves().call,
        foo_wfuse_contract.methods.totalSupply().call,

        foo_earn_contract.methods.balanceOf(wallet_address).call,
        fvksrg_earn_contract.methods.balanceOf(wallet_address).call,
    ];

    makeBatchRequest(web3, calls, "get_balances getFUSE()").then(resp => {
        let [
            fuse_balance,
            fuseone_balance,
            fuseone_info,
            ceus_balance,
            ceuse_balance,
            ceusone_balance,
            ceusone_info,
            fusd_balance,
            volt_balance,
            staked_fuse_with_coineus,
            lit_balance,
            gooddollar_balance,
            fvksrg_balance,
            lady_balance,
            foo_balance,
            dgnx_balance,

            btc_balance,
            eth_balance,
            bnb_balance,
            wfuse_balance,

            ceus_lit_balance,
            ceus_lit_reserves,
            ceus_lit_totalSupply,

            ceus_wfuse_balance,
            ceus_wfuse_reserves,
            ceus_wfuse_totalSupply,

            fuse_lit_balance,
            fuse_lit_reserves,
            fuse_lit_totalSupply,

            ceus_lit_farm_bal,
            ceus_fuse_farm_bal,
            ceus_lady_farm_bal,

            fuse_lit_farm_bal,
            fuse_fvksrg_farm_bal,
            ceus_fvksrg_farm_bal,
            lady_wfuse_farm_bal,
            foo_wfuse_farm_bal,

            ceus_lady_balance,
            ceus_lady_reserves,
            ceus_lady_totalSupply,

            fvksrg_fuse_balance,
            fvksrg_fuse_reserves,
            fvksrg_fuse_totalSupply,

            fvksrg_ceus_balance,
            fvksrg_ceus_reserves,
            fvksrg_ceus_totalSupply,

            dgnx_wfuse_balance,
            dgnx_wfuse_reserves,
            dgnx_wfuse_totalSupply,

            lady_wfuse_balance,
            lady_wfuse_reserves,
            lady_wfuse_totalSupply,

            foo_wfuse_balance,
            foo_wfuse_reserves,
            foo_wfuse_totalSupply,

            foo_earn_balance,
            fvksrg_earn_balance

        ] = resp;

        //fuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: 'native',
            balance: fuse_balance / (10 ** 18)
        }))
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: 'native',
            staked: staked_fuse_with_coineus / (10 ** 18)
        }))

        //wfuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',//wfuse
            balance: wfuse_balance / (10 ** 18)
        }));

        //fuseone
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xadF951967082452c46eA853158e0838F0EB6fee5',
            balance: fuseone_balance / (10 ** 18)
        }));
        store.dispatch(updateInfo({
            chain: 'fuse',
            contract_address: "0xadF951967082452c46eA853158e0838F0EB6fee5",
            info: (() => {
                const {account, balance, deposits, fees, transfersIn, transfersOut, withdrawals} = fuseone_info;
                return {account, balance, deposits, fees, transfersIn, transfersOut, withdrawals}
            })()
        }));

        //ceus
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
            balance: ceus_balance / (10 ** 18)
        }))

        //ceus.e
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x31Db8DC955a281d2fefAB5709d1Dfd9603679222',
            balance: ceuse_balance / (10 ** 18)
        }))

        //ceusone
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC',
            balance: ceusone_balance / (10 ** 18)
        }))
        store.dispatch(updateInfo({
            chain: 'fuse',
            contract_address: "0xE27431FACb1F093BA164c9F86B3f484b39ad54CC",
            info: (() => {
                const {account, balance, deposits, fees, transfersIn, transfersOut, withdrawals} = ceusone_info;
                return {account, balance, deposits, fees, transfersIn, transfersOut, withdrawals}
            })()
        }));

        //dgnx
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x13125265584D58cd45075e1efF47aEe083f4C1C6',
            balance: dgnx_balance / (10 ** 18)
        }))

        //fusd2
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F',//fUSD
            balance: fusd_balance / (10 ** 18)
        }));

        //volt
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4',//volt
            balance: volt_balance / (10 ** 18)
        }));

        //lit
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F',//lit
            balance: lit_balance / (10 ** 18)
        }));

        //gooddollar
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x495d133B938596C9984d462F007B676bDc57eCEC',//good dollar
            balance: gooddollar_balance / (10 ** 2)
        }));

        //fvksrg
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61',//fvksrg
            balance: fvksrg_balance / (10 ** 18)
        }));

        //$lady
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A',//lady
            balance: lady_balance / (10 ** 9)
        }));

        //foo
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9',//foo
            balance: foo_balance / (10 ** 18)
        }));

        //btc
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x33284f95ccb7B948d9D352e1439561CF83d8d00d',//btc
            balance: btc_balance / (10 ** 8)
        }));

        //eth
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xa722c13135930332Eb3d749B2F0906559D2C5b99',//eth
            balance: eth_balance / (10 ** 18)
        }));

        //bnb
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x6acb34b1Df86E254b544189Ec32Cf737e2482058',//bnb
            balance: bnb_balance / (10 ** 18)
        }));

        // LP

        //ceus/lit
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',//ceus/lit lp
            balance: ceus_lit_balance / (10**18)
        }));

        const ceus_lit_total = parseInt(ceus_lit_balance) + parseInt(ceus_lit_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',//ceus/lit lp
            lp: {
                token0: ceus_lit_reserves.reserve0 / (10**18) * ceus_lit_total/ceus_lit_totalSupply,
                token1: ceus_lit_reserves.reserve1 / (10**18) * ceus_lit_total/ceus_lit_totalSupply,
                share: ceus_lit_total/ceus_lit_totalSupply * 100,
                reserve0: ceus_lit_reserves.reserve0 / (10**18),
                reserve1: ceus_lit_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"],
                    token1: tokenList.tokens.fuse["0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"]
                }
            }
        }));

        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',
            staked: ceus_lit_farm_bal / (10 ** 18)
        }))

        //ceus/fuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',//ceus/wfuse lp
            balance: ceus_wfuse_balance / (10**18)
        }));

        const ceus_fuse_total = parseInt(ceus_wfuse_balance) + parseInt(ceus_fuse_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',//ceus/wfuse lp
            lp: {
                token0: ceus_wfuse_reserves.reserve0 / (10**18) * ceus_fuse_total/ceus_wfuse_totalSupply,
                token1: ceus_wfuse_reserves.reserve1 / (10**18) * ceus_fuse_total/ceus_wfuse_totalSupply,
                share: ceus_fuse_total/ceus_wfuse_totalSupply * 100,
                reserve0: ceus_wfuse_reserves.reserve0 / (10**18),
                reserve1: ceus_wfuse_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',
            staked: ceus_fuse_farm_bal / (10 ** 18)
        }));

        //fuse/lit
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc',//fuse/lit lp
            balance: fuse_lit_balance / (10**18)
        }));
        const fuse_lit_total = parseInt(fuse_lit_balance) + parseInt(fuse_lit_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc',//fuse/lit lp
            lp: {
                token0: fuse_lit_reserves.reserve0 / (10**18) * fuse_lit_total/fuse_lit_totalSupply,
                token1: fuse_lit_reserves.reserve1 / (10**18) * fuse_lit_total/fuse_lit_totalSupply,
                share: fuse_lit_total/fuse_lit_totalSupply * 100,
                reserve0: fuse_lit_reserves.reserve0 / (10**18),
                reserve1: fuse_lit_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"]
                }
            }
        }));

        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc',
            staked: fuse_lit_farm_bal / (10 ** 18)
        }))

        //ceus/lady
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xcEAB8d0303924D3DB5B3a3eD178F055603398845',//ceus/lady lp
            balance: ceus_lady_balance / (10**18)
        }));

        const ceus_lady_total = parseInt(ceus_lady_balance) + parseInt(ceus_lady_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0xcEAB8d0303924D3DB5B3a3eD178F055603398845',//ceus/lady lp
            lp: {
                token0: ceus_lady_reserves.reserve0 / (10**18) * ceus_lady_total/ceus_lady_totalSupply,
                token1: ceus_lady_reserves.reserve1 / (10**9) * ceus_lady_total/ceus_lady_totalSupply,
                share: ceus_lady_total/ceus_lady_totalSupply * 100,
                reserve0: ceus_lady_reserves.reserve0 / (10**18),
                reserve1: ceus_lady_reserves.reserve1 / (10**9),
                tokens: {
                    token1: tokenList.tokens.fuse["0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A"],
                    token0: tokenList.tokens.fuse["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0xcEAB8d0303924D3DB5B3a3eD178F055603398845',
            staked: ceus_lady_farm_bal / (10 ** 18)
        }));

        // fvksrg/fuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD',
            balance: fvksrg_fuse_balance / (10**18)
        }));

        const fvkrg_fuse_total = parseInt(fvksrg_fuse_balance) + parseInt(fuse_fvksrg_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD',
            lp: {
                token0: fvksrg_fuse_reserves.reserve0 / (10**18) * fvkrg_fuse_total/fvksrg_fuse_totalSupply,
                token1: fvksrg_fuse_reserves.reserve1 / (10**18) * fvkrg_fuse_total/fvksrg_fuse_totalSupply,
                share: fvkrg_fuse_total/fvksrg_fuse_totalSupply * 100,
                reserve0: fvksrg_fuse_reserves.reserve0 / (10**18),
                reserve1: fvksrg_fuse_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD',
            staked: fuse_fvksrg_farm_bal / (10 ** 18)
        }))

        // fvksrg/ceus
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0xB6850233c408Bbf58c3B2beC945394114cB08Fd0',
            balance: fvksrg_ceus_balance / (10**18)
        }));
        const fvkrg_ceus_total = parseInt(fvksrg_ceus_balance) + parseInt(ceus_fvksrg_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0xB6850233c408Bbf58c3B2beC945394114cB08Fd0',
            lp: {
                token0: fvksrg_ceus_reserves.reserve0 / (10**18) * fvkrg_ceus_total/fvksrg_ceus_totalSupply,
                token1: fvksrg_ceus_reserves.reserve1 / (10**18) * fvkrg_ceus_total/fvksrg_ceus_totalSupply,
                share: fvkrg_ceus_total/fvksrg_ceus_totalSupply * 100,
                reserve0: fvksrg_ceus_reserves.reserve0 / (10**18),
                reserve1: fvksrg_ceus_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"],
                    token1: tokenList.tokens.fuse["0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0xB6850233c408Bbf58c3B2beC945394114cB08Fd0',
            staked: ceus_fvksrg_farm_bal / (10 ** 18)
        }));

        // dgnx/wfuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61',
            balance: dgnx_wfuse_balance / (10**18)
        }));
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61',
            lp: {
                token0: dgnx_wfuse_reserves.reserve0 / (10**18) * dgnx_wfuse_balance/dgnx_wfuse_totalSupply,
                token1: dgnx_wfuse_reserves.reserve1 / (10**18) * dgnx_wfuse_balance/dgnx_wfuse_totalSupply,
                share: dgnx_wfuse_balance/dgnx_wfuse_totalSupply * 100,
                reserve0: dgnx_wfuse_reserves.reserve0 / (10**18),
                reserve1: dgnx_wfuse_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0x13125265584D58cd45075e1efF47aEe083f4C1C6"]
                }
            }
        }));

        // lady/wfuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586',
            balance: lady_wfuse_balance / (10**18)
        }));

        const lady_wfuse_total = parseInt(lady_wfuse_balance) + parseInt(lady_wfuse_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586',
            lp: {
                token0: lady_wfuse_reserves.reserve0 / (10**18) * lady_wfuse_total/lady_wfuse_totalSupply,
                token1: lady_wfuse_reserves.reserve1 / (10**9) * lady_wfuse_total/lady_wfuse_totalSupply,
                share: lady_wfuse_total/lady_wfuse_totalSupply * 100,
                reserve0: lady_wfuse_reserves.reserve0 / (10**18),
                reserve1: lady_wfuse_reserves.reserve1 / (10**9),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586',
            staked: lady_wfuse_farm_bal / (10 ** 18)
        }));

        // foo/wfuse
        store.dispatch(updateBalances({
            chain: 'fuse',
            contract_address: '0x4f47a2c360266230795CFBca80b7C89EdA52Cd52',
            balance: foo_wfuse_balance / (10**18)
        }));

        const foo_wfuse_total = parseInt(foo_wfuse_balance) + parseInt(foo_wfuse_farm_bal)
        store.dispatch(updateLPInfo({
            chain: 'fuse',
            contract_address: '0x4f47a2c360266230795CFBca80b7C89EdA52Cd52',
            lp: {
                token0: foo_wfuse_reserves.reserve0 / (10**18) * foo_wfuse_total/foo_wfuse_totalSupply,
                token1: foo_wfuse_reserves.reserve1 / (10**18) * foo_wfuse_total/foo_wfuse_totalSupply,
                share: foo_wfuse_total/foo_wfuse_totalSupply * 100,
                reserve0: foo_wfuse_reserves.reserve0 / (10**18),
                reserve1: foo_wfuse_reserves.reserve1 / (10**18),
                tokens: {
                    token0: tokenList.tokens.fuse["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"],
                    token1: tokenList.tokens.fuse["0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9"]
                }
            }
        }));
        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x4f47a2c360266230795CFBca80b7C89EdA52Cd52',
            staked: foo_wfuse_farm_bal / (10 ** 18)
        }));



        // EARN

        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9', //foo
            staked: foo_earn_balance / (10 ** 18)
        }))

        store.dispatch(updateStaked({
            chain: 'fuse',
            contract_address: '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61', //fvksrg
            staked: fvksrg_earn_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('fuse'));
    })
}

function getAVAX(pairs, rpc, wallet_address) {

    const web3AVAX = new Web3(rpc);
    const ceus_contract = new web3AVAX.eth.Contract(coineus_abi, '0x7efC6A44c996680b1738385b7E57E494Bd2f7981');
    const usdc_contract = new web3AVAX.eth.Contract(coineus_abi, '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E');
    const dgnx_contract = new web3AVAX.eth.Contract(coineus_abi, '0x51e48670098173025C477D9AA3f0efF7BF9f7812');

    const callsAVAX = [
        web3AVAX.eth.getBalance(wallet_address),
        ceus_contract.methods.balanceOf(wallet_address).call,
        usdc_contract.methods.balanceOf(wallet_address).call,
        dgnx_contract.methods.balanceOf(wallet_address).call,
    ];

    makeBatchRequest(web3AVAX, callsAVAX, "get_balances getAVAX()").then(resp => {
        let [
            avax_balance,
            ceus_balance,
            usdc_balance,
            dgnx_balance,
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: 'native',
            balance: avax_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: '0x7efC6A44c996680b1738385b7E57E494Bd2f7981', //ceus
            balance: ceus_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', //usdc
            balance: usdc_balance / (10 ** 6)
        }))

        store.dispatch(updateBalances({
            chain: 'avax',
            contract_address: '0x51e48670098173025C477D9AA3f0efF7BF9f7812', //dgnx
            balance: dgnx_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('avax'));

    })
        .catch(console.log)
}

function getBNB(pairs, rpc, wallet_address) {
    const web3BSC = new Web3(rpc);
    const fuse_contract = new web3BSC.eth.Contract(coineus_abi, '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3');
    const busd_contract = new web3BSC.eth.Contract(coineus_abi, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');
    const usdc_contract = new web3BSC.eth.Contract(coineus_abi, '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d');
    const usdt_contract = new web3BSC.eth.Contract(coineus_abi, '0x55d398326f99059fF775485246999027B3197955');

    const callsBSC = [
        web3BSC.eth.getBalance(wallet_address),
        fuse_contract.methods.balanceOf(wallet_address).call,
        busd_contract.methods.balanceOf(wallet_address).call,
        usdc_contract.methods.balanceOf(wallet_address).call,
        usdt_contract.methods.balanceOf(wallet_address).call,
    ];

    makeBatchRequest(web3BSC, callsBSC, "get_balances getBNB()").then(resp => {
        let [
            bnb_balance,
            fuse_balance,
            busd_balance,
            usdc_balance,
            usdt_balance,
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: 'native',
            balance: bnb_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',//fuse
            balance: fuse_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',//busd
            balance: busd_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',//usdc
            balance: usdc_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'bnb',
            contract_address: '0x55d398326f99059fF775485246999027B3197955',//usdt
            balance: usdt_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('bnb'));

    })
}

function getETH(pairs, rpc, wallet_address) {
    const web3ETH = new Web3(rpc);
    const usdc_contract = new web3ETH.eth.Contract(coineus_abi, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48');
    const usdt_contract = new web3ETH.eth.Contract(coineus_abi, '0xdAC17F958D2ee523a2206206994597C13D831ec7');
    const ceus_contract = new web3ETH.eth.Contract(coineus_abi, '0x496443ea0c46Ea3aF7025F071EA3FBd5F1f1D560');
    const dgnx_contract = new web3ETH.eth.Contract(coineus_abi, '0x0000000000300dd8B0230efcfEf136eCdF6ABCDE');

    const callsETH = [
        web3ETH.eth.getBalance(wallet_address),
        usdc_contract.methods.balanceOf(wallet_address).call,
        usdt_contract.methods.balanceOf(wallet_address).call,
        ceus_contract.methods.balanceOf(wallet_address).call,
        dgnx_contract.methods.balanceOf(wallet_address).call
    ];

    makeBatchRequest(web3ETH, callsETH, "get_balances getETH()").then(resp => {
        let [
            eth_balance,
            usdc_balance,
            usdt_balance,
            ceus_balance,
            dgnx_balance
        ] = resp;

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: 'native',
            balance: eth_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',//usdc
            balance: usdc_balance / (10 ** 6)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',//usdt
            balance: usdt_balance / (10 ** 6)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0x496443ea0c46Ea3aF7025F071EA3FBd5F1f1D560',//ceus
            balance: ceus_balance / (10 ** 18)
        }))

        store.dispatch(updateBalances({
            chain: 'eth',
            contract_address: '0x0000000000300dd8B0230efcfEf136eCdF6ABCDE',//dgnx
            balance: dgnx_balance / (10 ** 18)
        }))

        store.dispatch(updateTotals('eth'));

    })
}