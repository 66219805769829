import {Link as RouterLink} from "react-router-dom";
import {
    Avatar,
    AvatarGroup, Divider,
    Grid, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Skeleton
} from "@mui/material";
import {CoineusUSDFormat} from "../utils/currency_format";
import useFarmInfo from "../hooks/useFarmInfo";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useSelector} from "react-redux";

export default function Farm() {

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>

        {/*WFUSE/CEUS*/}
        <FarmSummary
            contract_address="0x7243265f91e138a11548DF4253E3ec056b6348f8"
            network="fuse"
        />
        {/*CEUS/LIT*/}
        <FarmSummary
            contract_address="0xC2B83a3C3199Fb29Ce1d8Dfc681001AF3e97e004"
            network="fuse"
        />
        {/*WFUSE/FOO*/}
        <FarmSummary
            contract_address="0x92958736B792F97B9296678A5C9509a2a5b8af2F"
            network="fuse"
        />

    </Grid>
}

function FarmSummary(props) {

    const {contract_address, network} = props;

    const {farmInfo, loading} = useFarmInfo(contract_address, network);

    const {wallet} = useSelector(state => state.coineus);

    if(loading){
        return <Grid item xs={12}>
            <Paper style={{padding:20}}>
                <Skeleton />
                <Skeleton />
            </Paper>
        </Grid>
    }

    const pricePerToken = wallet.prices[network][farmInfo.stakingToken].inUSD;

    return <Grid item xs={12}>
        <Paper style={{
            borderLeft: farmInfo.stakedAmount > 0 ? '2px solid #fbca00' : '2px solid black'
        }}>
            <List>
                <ListItem
                    secondaryAction={
                        <IconButton
                            style={{right: -10}}
                            color="primary"
                            component={RouterLink} to={`/farm/${network}/${contract_address}`}
                        ><NavigateNextIcon/></IconButton>
                    }
                >
                    <ListItemAvatar style={{marginRight: 10}}>
                        <AvatarGroup>
                            <Avatar
                                src={`https://coineus.app/assets/tokens/${network}/${farmInfo.token0}/logo.png`}/>
                            <Avatar
                                src={`https://coineus.app/assets/tokens/${network}/${farmInfo.token1}/logo.png`}/>
                        </AvatarGroup>
                    </ListItemAvatar>
                    <ListItemText
                        primary={CoineusUSDFormat(farmInfo.totalStaked * pricePerToken)}
                        primaryTypographyProps={{fontWeight: 700}}
                        secondary="Total Value Staked"
                    />
                    <ListItemText
                        primary={farmInfo.apy.toFixed(2) + "%"}
                        secondary="Est. APY"
                        primaryTypographyProps={{textAlign:'right', fontWeight: 700}}
                        secondaryTypographyProps={{textAlign:'right'}}
                    />
                </ListItem>

            </List>
        </Paper>
    </Grid>
}