import Web3 from "web3";
import consensus_abi from "../abis/consensus_abi.json";
import block_reward_abi from "../abis/block_reward_abi.json";
import {makeBatchRequest} from "../utils/promisify";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid, InputAdornment, LinearProgress, Link,
    List,
    ListItem,
    ListItemText,
    Paper, Slide, Snackbar, Tab, Tabs,
    TextField,
    Typography
} from "@mui/material";
import BigNumber from "bignumber.js";
import {toWei, formatWei} from '../utils/format';
import CoineusLoading from "../components/CoineusLoading";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {CHAIN_IDS} from "../constants";
import {NetworkButton} from "../components/Coineus";

const web3 = new Web3('https://rpc.fuse.io');
const coineusValidatorAddress = "0xb27feaab8e4f4423e0b9c6f743e26e23a5c0c1b3";
const consensusAddress = "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79";
const blockRewardAddress = "0x63D4efeD2e3dA070247bea3073BCaB896dFF6C9B";
const consensusContract = new web3.eth.Contract(consensus_abi, consensusAddress);
const blockRewardsContract = new web3.eth.Contract(block_reward_abi, blockRewardAddress);

const calcRewardPerYourBlocks = (
    rewardPerBlock,
    stakeAmount,
    numberOfValidators,
    totalStakeAmount,
    fee) => new BigNumber(rewardPerBlock)
    .multipliedBy(new BigNumber(stakeAmount))
    .multipliedBy(numberOfValidators)
    .div(new BigNumber(totalStakeAmount))
    .multipliedBy((1 - fee))


export default function Staking() {

    const {wallet} = useSelector(state => state.coineus);

    function TransitionUp(props) {
        return <Slide {...props} direction="up"/>;
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("Testing Snackbar");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarTransition, setSnackbarTransition] = useState(undefined);

    const [pending, setPending] = useState(false);
    const [amount, setAmount] = useState("");
    const [data, setData] = useState({});
    const [connectedContract, setConnectedContract] = useState(0);
    const [action, setAction] = useState('stake');
    const [displayInUSD, setDisplayInUSD] = useState(false);

    const getConsensus = () => {

        const calls = [
            consensusContract.methods.delegators(coineusValidatorAddress).call,
            consensusContract.methods.totalStakeAmount().call,
            consensusContract.methods.stakeAmount(coineusValidatorAddress).call,
            consensusContract.methods.currentValidatorsLength().call,
            consensusContract.methods.validatorFee(coineusValidatorAddress).call,
            blockRewardsContract.methods.getBlocksPerYear().call,
            blockRewardsContract.methods.getBlockRewardAmount().call
        ];

        if (wallet.address && wallet.chain === 122) {
            calls.push(consensusContract.methods.delegatedAmount(wallet.address, coineusValidatorAddress).call)
            const web3 = new Web3(window.provider);
            setConnectedContract(new web3.eth.Contract(consensus_abi, consensusAddress));
        }

        makeBatchRequest(web3, calls).then(resp => {

            const [
                delegators,
                total_stake_amount,
                amount_delegated,
                num_validators,
                validator_fee,
                blocks_per_year,
                reward_per_block
            ] = resp;

            let staked_amount = 0;

            if (wallet.address && wallet.chain === 122) {
                staked_amount = resp[resp.length - 1];
            }

            setData({
                delegators,
                total_stake_amount,
                amount_delegated: amount_delegated / (10 ** 18),
                staked_amount,
                num_validators,
                validator_fee,
                blocks_per_year,
                reward_per_block,
                fuse_balance: wallet
            })

            // get delegators amount list
            // const aCalls = [];
            // delegators.map(d => {
            //     aCalls.push(consensusContract.methods.delegatedAmount(d,coineusValidatorAddress).call)
            // })
            // makeBatchRequest(web3, aCalls).then(resp => {
            //     delegators.map( (d,i) => {
            //         console.log(d, formatWei(resp[i]))
            //     })
            // });

        })
    }

    // preview for staking
    const rewardPerYourBlocks = calcRewardPerYourBlocks(
        data.reward_per_block || 0,
        toWei(amount) || 0,
        data.num_validators || 0,
        data.total_stake_amount || 0,
        formatWei(data.validator_fee) || 0
    )

    const average = rewardPerYourBlocks.div(data.num_validators || 0)
    const rewardPerYear = average.multipliedBy(data.blocks_per_year || 0)

    // Already staked
    const _rewardPerYourBlocks = calcRewardPerYourBlocks(
        data.reward_per_block || 0,
        data.staked_amount > 0 ? data.staked_amount : 1,
        data.num_validators || 0,
        data.total_stake_amount || 0,
        formatWei(data.validator_fee) || 0
    )
    const _average = _rewardPerYourBlocks.div(data.num_validators || 0)
    const _rewardPerYear = _average.multipliedBy(data.blocks_per_year || 0)
    const _estimatedAPR = _rewardPerYear.div(data.staked_amount > 0 ? data.staked_amount : 1)

    useEffect(() => {
        getConsensus();
        const interval = setInterval(getConsensus, 10000)
        return () => clearInterval(interval);
    }, [wallet.address, wallet.chain]);

    const delegate = async () => {
        try {
            const tx = connectedContract.methods.delegate(coineusValidatorAddress);
            const gas = await tx.estimateGas({
                from: wallet.address,
                value: toWei(amount)
            })
            const gasPrice = 10000000000;//await web3.eth.getGasPrice();
            const data = tx.encodeABI();
            const txData = {
                from: wallet.address,
                value: toWei(amount),
                to: coineusValidatorAddress,
                data: data,
                gas,
                gasPrice,
            }

            tx.send(txData)
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                    handleError(error)
                })
                .then(function (receipt) {
                    getConsensus();
                    setPending(false);
                    setAmount('');
                })
                .catch(() => {
                });
        } catch (e) {
            handleError({message: e.toString()})
        }

    }

    const withdraw = async () => {
        try {
            const tx = connectedContract.methods.withdraw(coineusValidatorAddress, toWei(amount))
            const gas = await tx.estimateGas({
                from: wallet.address
            })
            const gasPrice = 10000000000;//await web3.eth.getGasPrice();
            const data = tx.encodeABI();
            const txData = {
                from: wallet.address,
                to: coineusValidatorAddress,
                data: data,
                gas,
                gasPrice,
            }

            tx.send(txData)
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                    handleError(error)
                })
                .then(function (receipt) {
                    getConsensus();
                    setPending(false);
                    setAmount('');
                })
                .catch(() => {
                });
        } catch (e) {
            handleError({message: e.toString()})
        }
    }

    const handleError = (error) => {
        if (error.message) {
            setSnackbarTransition(() => TransitionUp)
            setSnackbarSeverity("error");
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);
        }
    }

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>

            <Grid item xs={12}>
                <Paper style={{padding: 20}} onClick={() => setDisplayInUSD(!displayInUSD)}>
                    <Typography
                        variant="h4">{displayInUSD ? CoineusUSDFormat(data.amount_delegated * wallet.prices.fuse?.native?.inUSD) : CoineusCryptoFormat(data.amount_delegated)} </Typography>

                    <Typography variant="body2">Total FUSE Staked with <Link href="https://console.fuse.io/stake/0xb27feaab8e4f4423e0b9c6f743e26e23a5c0c1b3" target="_blank">Fuseprime</Link></Typography>
                    <LinearProgress variant="determinate" value={(data.amount_delegated / 10000000) * 100}
                                    style={{marginTop: 10}}/>
                    <Typography variant="caption" textAlign="right"
                                style={{display: 'block'}}>{CoineusCryptoFormat(data.amount_delegated)} /
                        10,000,000</Typography>
                </Paper>
            </Grid>

            <Grid item xs={7}>
                <Paper style={{padding: 20, color: '#000', backgroundColor: '#fbca00'}}
                       onClick={() => setDisplayInUSD(!displayInUSD)}>
                    <Typography
                        variant="h6">{displayInUSD ? CoineusUSDFormat((data.staked_amount / (10 ** 18)) * wallet.prices.fuse?.native?.inUSD) : CoineusCryptoFormat(data.staked_amount / (10 ** 18))}</Typography>
                    <Typography variant="caption">Your FUSE Staked </Typography>

                </Paper>
            </Grid>

            <Grid item xs={5}>
                <Paper style={{padding: 20, color: '#000', backgroundColor: '#fbca00'}}>
                    <Typography
                        variant="h6">{`${isNaN(_estimatedAPR) ? "--" : (_estimatedAPR * 100).toFixed(2)} %`}</Typography>
                    <Typography variant="caption">Estimated APR</Typography>
                </Paper>
            </Grid>

            {
                data.staked_amount > 0 && <Grid item xs={12}>
                    <Paper style={{padding: '10px 20px', color: '#000', backgroundColor: '#fbca00'}}>
                        <List disablePadding>
                            <ListItem disableGutters disablePadding>
                                <ListItemText
                                    secondary="Est. Rewards Per Block"
                                    secondaryTypographyProps={{style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                                <ListItemText
                                    secondary={`${CoineusCryptoFormat(_rewardPerYourBlocks / (10 ** 18))} FUSE`}
                                    secondaryTypographyProps={{textAlign: 'right', style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemText
                                    secondary="Est. Rewards Per Day"
                                    secondaryTypographyProps={{style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                                <ListItemText
                                    secondary={`${CoineusCryptoFormat((_rewardPerYourBlocks * 12 * 24) / (10 ** 18))} FUSE`}
                                    secondaryTypographyProps={{textAlign: 'right', style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                            </ListItem>
                            <ListItem disableGutters disablePadding>
                                <ListItemText
                                    secondary="Est. Rewards Per Year"
                                    secondaryTypographyProps={{style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                                <ListItemText
                                    secondary={`${CoineusCryptoFormat(_rewardPerYear / (10 ** 18))} FUSE`}
                                    secondaryTypographyProps={{textAlign: 'right', style: {color: '#000'}}}
                                    style={{margin: 0}}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <Tabs
                        variant="fullWidth"
                        value={action}
                        onChange={(ev, val) => {
                            setAction(val)
                            setAmount('')
                        }}
                    >
                        <Tab id="stake" value="stake" label="STAKE"/>
                        <Tab id="unstake" value="unstake" label="UNSTAKE"/>
                    </Tabs>
                    {
                        action === "stake" && <Box style={{padding: 20}}>
                            <List>
                                <ListItem disableGutters disablePadding>
                                    <ListItemText
                                        secondary="FUSE Balance"
                                    />
                                    <ListItemText
                                        secondary={`${CoineusCryptoFormat(wallet.balances.fuse.native)} FUSE`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>
                            </List>
                            <FormControl fullWidth style={{marginBottom: 20}}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    value={amount}
                                    placeholder="Enter Amount"
                                    onChange={(ev) => {
                                        if (ev.target.value > 5000000) return;
                                        setAmount(ev.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Button variant="contained" onClick={() => {
                                                setAmount(wallet.balances.fuse.native - .1)
                                            }
                                            }>MAX</Button>
                                        </InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                <Button fullWidth variant="contained" disabled={!amount || amount > 5000000}
                                        onClick={delegate}>Stake</Button>
                            </NetworkButton>
                            <List style={{marginTop: 20}}>
                                <ListItem disableGutters disablePadding>
                                    <ListItemText
                                        secondary="Est. Rewards Per Block"
                                    />
                                    <ListItemText
                                        secondary={`${CoineusCryptoFormat(rewardPerYourBlocks / (10 ** 18))} FUSE`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>
                                <ListItem disableGutters disablePadding>
                                    <ListItemText
                                        secondary="Est. Rewards Per Day"
                                    />
                                    <ListItemText
                                        secondary={`${CoineusCryptoFormat((rewardPerYourBlocks * 12 * 24) / (10 ** 18))} FUSE`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>
                                <ListItem disableGutters disablePadding>
                                    <ListItemText
                                        secondary="Est. Rewards Per Year"
                                    />
                                    <ListItemText
                                        secondary={`${CoineusCryptoFormat(rewardPerYear / (10 ** 18))} FUSE`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    }

                    {
                        action === "unstake" && <Box style={{padding: 20}}>
                            <List>
                                <ListItem disableGutters disablePadding>
                                    <ListItemText
                                        secondary="Staked FUSE"
                                    />
                                    <ListItemText
                                        secondary={`${CoineusCryptoFormat(data.staked_amount / (10 ** 18) || "--")} FUSE`}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                    />
                                </ListItem>
                            </List>
                            <FormControl fullWidth style={{marginBottom: 20}}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    value={amount}
                                    placeholder="Enter Amount"
                                    onChange={(ev) => {
                                        if (ev.target.value > data.staked_amount / (10 ** 18)) return;
                                        setAmount(ev.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Button variant="contained" onClick={() => {
                                                setAmount(data.staked_amount / (10 ** 18))
                                            }
                                            }>MAX</Button>
                                        </InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            <Button fullWidth variant="contained"
                                    disabled={!amount || amount > data.staked_amount / (10 ** 18)}
                                    onClick={withdraw}>Unstake</Button>
                        </Box>
                    }

                </Paper>
            </Grid>

            <CoineusLoading open={pending} label="Transaction Pending"/>

            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                autoHideDuration={10000}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                TransitionComponent={snackbarTransition}
                sx={{bottom: {xs: 30}}}
            >
                <Alert
                    color={snackbarSeverity}
                    variant="filled"
                    elevation={6}
                    icon={false}
                >
                    <Typography>{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>

        </Grid>
    )
}